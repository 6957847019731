<template>
  <div class="container-fluid view-product">
    <b-modal
      id="newCart"
      ref="newCart"
      :hide-footer="true"
      :title="$t('product.select-basket')"
      centered
    >
      <form ref="form" class="pt-2">
        <div
          class="fs-16 font-weight-bold mb-3"
          v-if="activeCart.status !== null"
        >
          {{ $t("cart.select-existing") }}
        </div>
        <div class="row  " v-if="activeCart.status !== null">
          <div class="col-8">
            <div class=" border-bottom pb-4 mb-3">
              <select class="w-100" v-model="dropdownCart">
                <option
                  v-for="cart in activeCarts"
                  :key="cart.ordernr"
                  :value="cart"
                >
                  {{ cart.projekt }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-4 pl-0">
            <button
              class="mb-2 btn btn-primary w-100 text-center"
              type="button"
              @click="addProductToCart(dropdownCart)"
            >
              {{ $t("product.add") }}
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="fs-16 font-weight-bold mb-2">
              {{ $t("cart.or-new-basket") }}
            </div>
            <div class="row">
              <div class="col-8">
                <form ref="form" @submit.stop.prevent="createCart">
                  <b-form-group>
                    <b-form-input
                      :placeholder="$t('cart.new-basket-name')"
                      id="name-input"
                      v-model="newCartTitle"
                      required
                    ></b-form-input>
                  </b-form-group>
                </form>
              </div>
              <div class="col-4 pl-0">
                <button
                  class="mb-2 btn btn-primary w-100 text-center"
                  type="button"
                  @click="createCart"
                >
                  {{ $t("product.add") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>

    <div class="row subbar">
      <div v-if="!isLoading" class="container-fluid">
        <div class="side-push">
          <div class="row">
            <div class="col">
              <nav>
                <span
                  ><a href="/">{{ $t("menu.home") }}</a></span
                >
                <span
                  >/<a href="/">{{ $t("menu.products") }}</a></span
                >
                <span v-if="product.artikelkategori_huvud"
                  >/<router-link
                    :to="{
                      path: '/',
                      query: { category: product.artikelkategori_huvud }
                    }"
                    tag="a"
                  >
                    {{ product.artikelkategori_huvud }}
                  </router-link>
                </span>
                <span v-if="product.artikelkategori"
                  >/<router-link
                    :to="{
                      path: '/',
                      query: { subcategory: product.artikelkategori }
                    }"
                    tag="a"
                  >
                    {{ product.artikelkategori }}
                  </router-link></span
                >
                <span
                  >/<a href="/">{{ product.benamn1 }}</a></span
                >
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="side-push" style="margin-top: 30px">
      <div v-if="!isLoading" class="row">
        <div class="col-12 col-md-6">
          <VueSlickCarousel ref="c1" :asNavFor="$refs.c2" :focusOnSelect="true">
            <div
              class="product-image"
              v-for="image in product.images"
              :key="image.bild"
            >
              <div>
                <div class="current-image">
                  <img :src="image.bild" style="width: 100%" />
                </div>
              </div>
            </div>
          </VueSlickCarousel>

          <div class="small-product-images">
            <VueSlickCarousel
              ref="c2"
              :asNavFor="$refs.c1"
              :slidesToShow="5"
              :focusOnSelect="true"
            >
              <div v-for="image in product.images" :key="image.bild">
                <div
                  class="product-alt-image"
                  @click="product.main_image = image.bild"
                >
                  <img :src="image.bild" class="img-fluid" />
                </div>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <h1>{{ product.benamn1 }}</h1>

          <template v-if="product.benamn2">
            <div class="mb-4 small text-muted">{{ product.benamn2 }}</div>
          </template>
          <h4 class="brand">{{ product.varumarke }}</h4>
          <h4 class="price">
            <total-price :discount="false" :product="product"></total-price>
          </h4>

          <div
            v-if="product.parts.length === 0 && getPermission !== 0"
            class="cart"
          >
            <button
              :disabled="product.qty <= 0 || product.qty === '0'"
              class="minus"
              type="button"
              @click="product.qty = product.qty - 1"
            >
              -
            </button>
            <div class="quantity">
              <input
                v-model="product.qty"
                autocomplete="off"
                class="input-text qty text"
                inputmode="numeric"
                max=""
                min="0"
                name="quantity"
                placeholder=""
                size="4"
                step="1"
                title="Antal"
                type="number"
                value="1"
              />
            </div>
            <button
              class="plus"
              style="padding: 0 7px"
              type="button"
              @click="product.qty = product.qty + 1"
            >
              +
            </button>
            <b-button v-b-modal.newCart variant="btn btn-primary"
              >{{ $t("product.add-to-basket") }}
            </b-button>
          </div>

          <p
            v-if="product.text"
            class="mt-5"
            style="white-space: pre-wrap"
            v-html="nl2br(product.text.trim())"
          ></p>
          <div v-if="product.parts.length !== 0 && getPermission !== 0">
            <div class="details mb-23">
              <div class="row row-bordered product-main-row">
                <div class="col-4">
                  <div class="detail-label">
                    {{ product.benamn1 }}
                    <discount
                      :product="product"
                      class="pt-1 d-block"
                    ></discount>
                  </div>
                </div>
                <div class="col-3 text-right">
                  <div class="detail-info">
                    {{
                      $formatPrice(
                        product.pris - product.pris * (product.rabatt / 100),
                        product.valuta
                      )
                    }}
                  </div>
                </div>
                <div class="col-5 pt-2 text-right">
                  <div class="cart-multiple">
                    <button
                      :disabled="product.qty <= 0 || product.qty === '0'"
                      class="minus"
                      type="button"
                      @click="updateProductQty(-1)"
                    >
                      -
                    </button>
                    <input
                      v-model="product.qty"
                      autocomplete="off"
                      class="input-text qty-multiple qty text mt-1"
                      inputmode="numeric"
                      max=""
                      min="0"
                      name="quantity"
                      placeholder=""
                      size="4"
                      step="1"
                      title="Antal"
                      type="number"
                      value="1"
                    />
                    <button
                      class="plus"
                      type="button"
                      @click="updateProductQty(1)"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-for="part in product.parts"
                :key="part.benamn1"
                class="row row-bordered"
              >
                <div class="col-4">
                  <div style="font-weight: inherit !important">
                    {{ part.benamn1 }}<br />
                    <span
                      v-if="part.text"
                      class="text-muted mt-2 d-inline-block"
                      >{{ part.text }}</span
                    >
                  </div>
                </div>

                <div class="col-3 text-right">
                  <div class="detail-info">
                    {{ $formatPrice(part.pris, product.valuta) }}
                  </div>
                </div>
                <div class="col-5 pt-2 text-right">
                  <div
                    v-if="part.redigerbar !== 'N' && part.redigerbar !== 'R'"
                    class="cart-multiple"
                  >
                    <button
                      :disabled="part.qty <= 0 || part.qty === '0'"
                      class="minus"
                      type="button"
                      @click="updatePartQty(part, -1)"
                    >
                      -
                    </button>
                    <input
                      v-model="part.qty"
                      :min="part.redigerbar === 'R' ? 0 : 1"
                      autocomplete="off"
                      class="input-text qty-multiple qty text mt-1"
                      inputmode="numeric"
                      max=""
                      name="quantity"
                      placeholder=""
                      size="4"
                      step="1"
                      title="Antal"
                      type="number"
                      value="1"
                    />
                    <button
                      class="plus"
                      type="button"
                      @click="updatePartQty(part, 1)"
                    >
                      +
                    </button>
                  </div>

                  <div
                    v-if="part.redigerbar === 'N' || part.redigerbar === 'R'"
                    class="cart-multiple"
                  >
                    <input
                      v-model="part.qty"
                      autocomplete="off"
                      class="input-text qty-multiple qty text mt-1 mr-0"
                      disabled
                      inputmode="numeric"
                      placeholder=""
                      size="4"
                      title="Antal"
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right">
              <b-button v-b-modal.newCart variant="btn btn-sm btn-primary"
                >{{ $t("product.add-to-basket") }}
              </b-button>
            </div>
          </div>

          <div class="details mb-5">
            <div class="row row-bordered">
              <div class="col-3">
                <div class="detail-label">
                  {{ $t("product.articlenumber") }}
                </div>
              </div>
              <div class="col-9">
                <div class="detail-info">{{ product.artikelnr }}</div>
              </div>
            </div>
            <div v-if="product.rabatt !== null" class="row row-bordered">
              <div class="col-3">
                <div class="detail-label">{{ $t("product.discount") }}</div>
              </div>
              <div class="col-9">
                <div class="detail-info">
                  <discount
                    v-if="product.parts.length === 0"
                    :product="product"
                  ></discount>
                </div>
              </div>
            </div>

            <div v-if="product.kocncet" class="row row-bordered">
              <div class="col-3">
                <div class="detail-label">{{ $t("product.concept") }}</div>
              </div>
              <div class="col-9">
                <div class="detail-info">
                  {{ product.kocncet.replace(/,/g, ", ") }}
                </div>
              </div>
            </div>

            <div v-if="product.certifiering" class="row row-bordered">
              <div class="col-3">
                <div class="detail-label">{{ $t("product.certificate") }}</div>
              </div>
              <div class="col-9">
                <div class="detail-info">
                  {{ product.certifiering.replace(/,/g, ", ") }}
                </div>
              </div>
            </div>
            <div class="row row-bordered" v-if="product.co2_metod">
              <div class="col-3">
                <div class="detail-label">{{ $t("product.climate") }}</div>
              </div>
              <div class="col-9">
                <div class="detail-info">
                  <template v-if="product.co2_metod === 'O'">
                    {{ product.co2_metod_t }}
                  </template>
                  <template v-if="product.co2_metod !== 'O' && product.climate">
                    {{ product.climate }} kg CO<sub>2</sub>&nbsp;
                    <span v-if="product.co2_metod_t"
                      >({{ product.co2_metod_t }})</span
                    >
                  </template>
                </div>
              </div>
            </div>

            <div v-if="product.delivery_time_precise" class="row row-bordered">
              <div class="col-3">
                <div class="detail-label">{{ $t("product.delivery") }}</div>
              </div>
              <div class="col-9">
                <div class="detail-info">
                  {{ product.delivery_time_precise }}
                  <template v-if="product.delivery_time_unit === 'A'">{{
                    $t("product.workdays")
                  }}</template>
                  <template v-if="product.delivery_time_unit === 'V'">{{
                    $t("product.weeks")
                  }}</template>
                  <template v-if="product.delivery_time_unit === 'D'">{{
                    $t("product.days")
                  }}</template>
                </div>
              </div>
            </div>
            <div
              v-if="product.delivery_time && !product.delivery_time_precise"
              class="row row-bordered"
            >
              <div class="col-3">
                <div class="detail-label">{{ $t("product.delivery") }}</div>
              </div>
              <div class="col-9">
                <div class="detail-info">
                  {{ product.delivery_time }} {{ $t("product.days") }}
                </div>
              </div>
            </div>
            <div
              v-if="
                product.hojd ||
                  product.bredd_djup ||
                  product.djup ||
                  product.langd ||
                  product.diameter
              "
              class="row row-bordered"
            >
              <div class="col-3">
                <div class="detail-label">{{ $t("product.measurement") }}</div>
              </div>
              <div class="col-9">
                <div class="detail-info">
                  <template v-if="product.hojd">
                    <span>{{ $t("product.height") }}</span>
                    {{ product.hojd }}
                    <template v-if="product.delta > 0">
                      - {{ parseInt(product.hojd) + parseInt(product.delta) }}
                    </template>
                    mm
                  </template>

                  <template v-if="product.bredd_djup">
                    <span>{{ $t("product.width") }}</span>
                    {{ product.bredd_djup }} mm
                  </template>

                  <template v-if="product.djup">
                    <span>{{ $t("product.depth") }}</span>
                    {{ product.djup }} mm
                  </template>

                  <template v-if="product.diameter">
                    <span>{{ $t("product.diameter") }}</span>
                    {{ product.diameter }} mm
                  </template>

                  <template v-if="product.sitthojd">
                    <span>{{ $t("product.sitting_height") }}</span>
                    {{ product.sitthojd }}
                    <template v-if="product.delta > 0">
                      -
                      {{ parseInt(product.sitthojd) + parseInt(product.delta) }}
                    </template>
                    mm
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!isLoading && relatedProducts.length !== 0" class="row">
        <div class="col mt-5">
          <div class="mt-5 text-center mb-5">
            <h2>{{ $t("product.related-products") }}</h2>
          </div>
          <div class="row">
            <div
              v-for="relatedproduct in relatedProducts"
              :key="relatedproduct.artikelnr"
              class="col-12 col-md-2-5"
            >
              <single-product :product="relatedproduct"></single-product>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputAPI from "@/services/api/input";
import SingleProduct from "@/components/producs/SingleProduct";
import TotalPrice from "@/components/producs/TotalPrice";
import Discount from "@/components/producs/Discount";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "ViewSingleProduct",
  components: {
    SingleProduct,
    TotalPrice,
    Discount,
    VueSlickCarousel
  },
  mounted() {
    this.getProduct();
    this.initializeDropdownCart();
  },
  props: {
    sku: String
  },
  data() {
    return {
      isLoading: true,
      newCartTitle: "",
      dropdownCart: null,
      product: {
        parts: [],
        artikelkategori_huvud: 1
      },
      qty: 1
    };
  },
  computed: {
    activeCarts() {
      return this.$store.getters.getActiveCarts;
    },
    activeCart() {
      return this.$store.getters.getActiveCart;
    },
    relatedProducts() {
      return this.$store.getters.relatedProducts(
        this.product.artikelkategori_huvud
      );
    },
    placeholdersNeeded() {
      const imageCount = this.product.images.length;
      if (imageCount < 5) {
        return 5 - imageCount;
      } else if (imageCount >= 5 && imageCount < 10) {
        return 10 - imageCount;
      } else if (imageCount >= 10 && imageCount < 15) {
        return 15 - imageCount;
      }
      return 0; // No placeholders if 15 or more images
    },
    getPermission() {
      return this.$store.getters.getPermission;
    }
  },
  watch: {
    "$route.params": {
      handler(newValue) {
        this.getProduct();
        window.scrollTo(0, 0);
      },
      immediate: true
    }
  },
  methods: {
    initializeDropdownCart() {
      if (this.activeCart && this.activeCarts.length > 0) {
        const activeCartObj = this.activeCarts.find(
          cart => cart.ordernr === this.activeCart.ordernr
        );

        if (activeCartObj) {
          this.dropdownCart = activeCartObj;
        }
      }
    },
    updatePartQty(part, qty) {
      part.qty = parseInt(qty) + parseInt(part.qty);
      part.original_qty = part.qty;
    },
    nl2br(str) {
      if (typeof str === "undefined" || str === null) {
        return "";
      }
      return (str + "").replace(/(\r\n|\n\r|\r|\n)/g, "<br>");
    },
    updateProductQty(qty) {
      let oldQty = this.product.qty;

      this.product.qty = this.product.qty + qty;

      if (oldQty !== 0) {
        var multiply = this.product.qty / oldQty;

        this.product.parts.forEach(part => {
          if (part.redigerbar !== "A") {
            part.qty = parseInt(part.antal) * this.product.qty;
          }
        });
      }
    },

    addProductToCart(cart) {
      let cart_id = cart.ordernr;

      let parts = [];

      if (this.product.parts.length !== 0) {
        1;

        this.product.parts.forEach(part => {
          parts.push({
            qty: part.qty,
            line_no: part.radnr,
            list_no: part.listnr,
            subline_no: part.underrad
          });
        });
      }
      return InputAPI.addLineToCart(
        cart_id,
        this.$store.getters.getCurrentShop.shop_id,
        {
          qty: this.product.qty,
          line_no: this.product.radnr,
          list_no: this.product.listnr,
          subline_no: 0,
          parts: parts
        }
      )
        .then(response => {
          this.$store.dispatch("setActiveCart", response.data.data);
          this.$toast.success(
            this.$t("product.product-added-to-basket") +
              " (" +
              this.$store.getters.getActiveCart.projekt +
              ")",
            {
              timeout: 3000
            }
          );
          this.$refs["newCart"].hide();
          this.initializeDropdownCart();
        })
        .catch(error => {
          throw error;
        });
    },

    getProduct: function() {
      this.product = this.$store.getters.getProduct(
        this.$route.params.shop,
        this.$route.params.uid
      );
      this.isLoading = false;
      InputAPI.getSingleProduct(this.$route.params.shop, this.$route.params.uid)
        .then(response => {
          this.setData(response.data.data);
          this.isLoading = false;
        })
        .catch(error => {});
    },

    createCart() {
      this.$store
        .dispatch("createCart", {
          name: this.newCartTitle,
          shop_id: this.$store.getters.getCurrentShop.shop_id
        })
        .then(response => {
          this.addProductToCart(response);
          this.$store.dispatch("getCarts");
          this.$refs["newCart"].hide();
          this.$toast.success(this.$t("cart.basket-created"), {
            timeout: 3000
          });
          this.newCartTitle = "";
          this.initializeDropdownCart();
        });
    },
    setData(payload) {
      this.product = payload;
      this.isLoading = false;
    }
  }
};
</script>

<template>
  <span style="font-weight: 400 !important" v-if="product.rabatt !== null"
    >{{ product.rabatt }}% {{ $t("product.discount-text") }}</span
  >
</template>

<script>
export default {
  name: "DiscountBadge",
  props: {
    product: Object,
  },
};
</script>

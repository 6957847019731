<template>
  <div class="container-fluid cart">
    <div class="row subbar">
      <div class="container-fluid">
        <div class="side-push">
          <div class="row">
            <div class="col">
              <nav>
                <span
                  ><a href="/">{{ $t("menu.home") }}</a></span
                >
                <span
                  >/<a href="/">{{ $t("menu.orders") }}</a></span
                >
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="side-push">
      <div class="row title-wrapper">
        <div class="col-6">
          <h1 class="mt-0 mb-0">{{ $t("menu.orders") }}: #{{ id }}</h1>
        </div>
      </div>
    </div>
    <div class="row mb-4" style="background: #f5f5f5">
      <div class="col-12 pt-5 pb-5">
        <div class="side-push">
          <div class="row">
            <div class="col-4">
              <h6>{{ $t("cart.contact") }}</h6>
              {{ activeCart.referens }}
            </div>
            <div class="col-4">
              <h6>Status</h6>
              {{ activeCart.status_text }}
            </div>
            <div class="col-4">
              <h6>{{ $t("cart.currency") }}</h6>
              {{ activeCart.currency }}
            </div>
          </div>

          <div class="row pt-5">
            <div class="col-4">
              <h6>{{ $t("cart.customer-reference") }}</h6>
              {{ activeCart.reference }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="side-push">
      <div class="row">
        <div class="col-12">
          <table class="cart-table" cellspacing="0">
            <thead>
              <tr>
                <th class="product-name" width="34%">
                  {{ $t("cart.Produkt") }}
                </th>
                <th class="product-article-number">
                  {{ $t("cart.articlenumber") }}
                </th>

                <th class="product-price">{{ $t("cart.unit-price") }}</th>
                <th class="product-quantity">{{ $t("cart.quantity") }}</th>
                <th class="product-subtotal">{{ $t("cart.sub-price") }}</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="activeCart.lines.length === 0">
                <tr>
                  <td colspan="5" class="text-center pt-5">
                    <h5 class="text-muted">Din varukorg är tom</h5>
                  </td>
                </tr>
              </template>
              <template v-if="activeCart.lines.length !== 0">
                <tr
                  class="woocommerce-cart-form__cart-item cart_item"
                  v-for="cartLine in activeCart.lines"
                  :key="cartLine.id"
                >
                  <td>{{ cartLine.benamn1 }}</td>
                  <td>{{ cartLine.artikelnr }}</td>
                  <td>{{ formatPrice(cartLine.pris) }}</td>
                  <td>{{ cartLine.antal }} st</td>
                  <td>{{ formatPrice(cartLine.pris * cartLine.antal) }}</td>
                </tr>
              </template>
            </tbody>
            <thead>
              <tr>
                <th class="product-name" colspan="2"></th>
                <th class="product-quantity">{{ $t("cart.total") }}</th>
                <th class="product-subtotal">
                  {{ getQtyOrder(activeCart) }} st
                </th>
                <th class="product-subtotal">{{ getSum(activeCart) }}</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
  },
  methods: {
    getSum(order) {
      let sum = order.lines.reduce(
        (total, arg) => parseFloat(total) + parseFloat(arg.pris) * arg.antal,
        0
      );
      return this.formatPrice(sum);
    },
    getQtyOrder(order) {
      return order.lines.reduce(
        (total, arg) => parseFloat(total) + parseFloat(arg.antal),
        0
      );
    },
    formatPrice(price) {
      return new Intl.NumberFormat("sv-SE", {
        style: "currency",
        currency: this.activeCart.currency,
        minimumFractionDigits: 0,
      }).format(price);
    },
  },
  computed: {
    activeCart() {
      return this.$store.getters.getCartById(this.id);
    },
  },

  data() {
    return {
      currentCart: {},
      cart: {
        name: "",
      },
    };
  },
};
</script>
